import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Avatar from '../../assets/Icons/NavBarIcons/Avatar.svg';
import LogoutIcon from '../../assets/Icons/NavBarIcons/Logout.svg';
import SlackIcon from '../../assets/Icons/NavBarIcons/SlackLogo.svg';
import './SubMenu.css';
import { IPublicClientApplication } from '@azure/msal-browser';
import { getLogin } from '../../helpers/authHelper';

interface SubMenuProps {
  msalInstance: IPublicClientApplication
}

const SubMenu = (props: SubMenuProps) => {
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [initials, setInitials] = useState('');
  const location = useLocation();

  const getUserName = useCallback(async() => {
    const login = await getLogin(props.msalInstance);
    return login.account?.name;
  }, [props.msalInstance]);

  useEffect(() => {
    const fetchUserName = async () => {
      const username = await getUserName();
      if (username === undefined) {
        setInitials('');
        return;
      }
      setInitials(username.split(' ').map((name) => name[0]).join(''));
    };
    
    fetchUserName();
  }, [getUserName]);

  return <div className='submenu-container'>
    <button
      className={`nav-button ${subMenuOpen || location.pathname === '/SlackIntegration' ? 'active' : ''}`}
      style={{ width: '151px', cursor: 'pointer', height: '88px' }}
      onClick={() => setSubMenuOpen(s => !s)}
    >
      <img src={Avatar} alt='Avatar Icon' className='' style={{width: '14px', height: '14px'}} />
      {initials}
    </button>
    <div className='submenu' style={{display: subMenuOpen ? 'flex' : 'none'}}>
      {<RouterLink
       to='/SlackIntegration'
      >
        <button
          className='request-button submenu-button'
          onClick={() => setSubMenuOpen(s => !s)}
        >
          <img src={SlackIcon} alt='Slack Icon' className='logout-icon' />
          Slack Integration
        </button>
      </RouterLink>}
      <button
        className='request-button sign-out submenu-button'
        onClick={async () => await props.msalInstance.logoutRedirect()}
      >
        <img src={LogoutIcon} alt='Logout Icon' className='logout-icon' />
        Sign Out
      </button>
    </div>
  </div>;
};

export default SubMenu;