import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { BankHoliday, Absence, User, Resource, CalendarFilter } from '../../Types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import BankHolidayIcon from '../../assets/Icons/CalendarIcons/Absences/BankHolidayIcon.svg';
import './Calendar.css';
import { getLogin } from '../../helpers/authHelper';
//Calendar absence icons
import DayOffIcon from '../../assets/Icons/CalendarIcons/Absences/DayOff.svg';
import SickLeaveIcon from '../../assets/Icons/CalendarIcons/Absences/SickLeave.svg';
import OtherIcon from '../../assets/Icons/CalendarIcons/Absences/Other.svg';
import UnpaidLeaveIcon from '../../assets/Icons/CalendarIcons/Absences/UnpaidLeave.svg';
import VacationIcon from '../../assets/Icons/CalendarIcons/Absences/Vacation.svg';
import WorkTravelIcon from '../../assets/Icons/CalendarIcons/Absences/WorkTravel.svg';
import areSetsEqual from '../../helpers/sets';
import { getAbsenceColor, fetchBankHolidays, formatCalendarDate, formatDate, handleRequestHandled, getFormattedPeriod } from '../../helpers/absenceRequests';
import { EventSourceInput } from '@fullcalendar/core';
import { exportAbsencesForMonth, exportAbsencesForYear } from '../../helpers/exports';
import { AuthenticationResult } from '@azure/msal-browser';
import getUsername from '../../helpers/username';
import { getOverlappingDates } from '../../helpers/absences';

const getAbsenceIcon = (absence: Absence) => {
  const title = absence.reason === undefined ?
    undefined : `${getFormattedPeriod(absence.startDate, absence.endDate)}\n${absence.reason}${absence.information === '' ? '' :
    ' - '}${absence.information}`;
  const getReason = () => {
    switch(absence.reason) {
      case 'Vacation':
        return <img src={VacationIcon} alt={title} title={title} />;
      case 'Sick Leave':
        return <img src={SickLeaveIcon} alt={title} title={title} />;
      case 'Day Off':
        return <img src={DayOffIcon} alt={title} title={title} />;
      case 'Unpaid Leave':
        return <img src={UnpaidLeaveIcon} alt={title} title={title} />;
      case 'Work Travel':
        return <img src={WorkTravelIcon} alt={title} title={title} />;
      default:
        return <img src={OtherIcon} alt={title} title={title} />;
    }
  };
  
  if (absence.status === 'Requested') {
    return <React.Fragment>
      <div title={title} className='absence-Requested'></div>
      {getReason()}
    </React.Fragment>;
  }
  return getReason();
};

interface CalendarProps {
  groups?: string[]
}

const Calendar = (props: CalendarProps) => {
  const { groups } = props;
  const [loggedInUser, setLoggedInUser] = useState<User | undefined>(undefined);
  const [absences, setAbsences] = useState<Absence[]>([]);
  const [employees, setEmployees] = useState<User[]>([]);
  const [allEmployees, setAllEmployees] = useState<User[]>([]);
  const [bankHolidays, setBankHolidays] = useState<BankHoliday[]>([]);
  const [filterName, setFilterName] = useState('');
  const [sortedResources, setSortedResources] = useState<Resource[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [totalAway, setTotalAway] = useState<number>(0);
  const now = new Date();
  now.setUTCFullYear(now.getFullYear(), now.getMonth(), 1);
  now.setUTCHours(0, 0, 0, 0);
  const [calendarDate, setCalendarDate] = useState(now);
  const [calendarYear, setCalendarYear] = useState(calendarDate.getUTCFullYear());
  const [countries, setCountries] = useState<Set<string>>(new Set());
  const [calendarResourceAreaWidth, setCalendarResourceAreaWidth] = useState(window.innerWidth);
  const [calendarFilter, setCalendarFilter] = useState<CalendarFilter>('all');
  const [directReports, setDirectReports] = useState<User[]>([]);
  const [indirectReports, setIndirectReports] = useState<User[]>([]);
  const msal = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const fullCalendar: React.RefObject<FullCalendar> = React.createRef();

  useEffect(() => {
    const handleResize = () => {
      setCalendarResourceAreaWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  useEffect(() => {
    if (calendarDate.getUTCFullYear() !== calendarYear) {
      setCalendarYear(calendarDate.getUTCFullYear());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[calendarDate]);

  const fetchAbsences = useCallback(async(login: AuthenticationResult) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASEURL}/absences`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      setAbsences(data);
    } catch (error) {
      console.error('Error fetching absences:', error);
    }
  },[]);

  useEffect(() => {
    const fetchMyTeam = async() => {
      try {
        const login = await getLogin(msal.instance);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASEURL}/my-team`,
          {
            headers: {
              Authorization: `Bearer ${login.idToken}`,
            },
          }
        );
  
        const directReportsData = response.data.filter((user: User) => user.leadId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);
        const indirectReportsData = response.data.filter((user: User) => user.leadId !== msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);
  
        const sortedDirectReports = directReportsData.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
  
        if (indirectReportsData) {
          setIndirectReports(indirectReportsData.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
          }));
        }
  
        setDirectReports(sortedDirectReports);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    if (groups && groups.includes(process.env.REACT_APP_LEAD_GROUP as string)) {
      fetchMyTeam();
    }
  },[msal.instance, groups]);

  //My (logged in users) Absences
  useEffect(() => {
    const fetchUserProfile = async (login: AuthenticationResult) => {
      try {
        const response = await axios.get<User[]>(
          `${process.env.REACT_APP_BACKEND_BASEURL}/users`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${login.idToken}`,
            },
          }
        );

        if (response.data) {
          const user = response.data.find((user) => user.userId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);
          setLoggedInUser(user);

          const sortedEmployees = response.data.filter((employee) => employee.userId !== user?.userId).sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          setAllEmployees(sortedEmployees);
          setEmployees(sortedEmployees);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const getData = async(): Promise<void> => {
      const login = await getLogin(msal.instance);
      fetchUserProfile(login);
      fetchAbsences(login);
    };
    getData();
  }, [isAuthenticated, msal.instance, fetchAbsences]);

  useEffect(() => {
    const fetch = async() => {
      const bankHolidaysResponse = await fetchBankHolidays(countries, calendarYear, msal.instance);
      if (bankHolidaysResponse !== undefined) {
        setBankHolidays(bankHolidaysResponse);
      }
    };
    fetch();
  },[countries, calendarYear, msal.instance]);

  useEffect(() => {
    if (employees && loggedInUser) {
      const newCountries = [loggedInUser, ...employees].reduce((acc: Set<string>, cur) => {
        if (typeof cur.c === 'string') {
          acc.add(cur.c);
        }
        return acc;
      },new Set<string>());

      if (!areSetsEqual(countries, newCountries)) {
        setCountries(newCountries);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, loggedInUser]);

  //This is the resource timeline view, to associate data from the resource column with calendar events we have to give it a resourceId, we have two sections in the resource column
  //my-absences and then the general department view which would be then additionally for the Management

  useEffect(() => {
    if (loggedInUser !== undefined) {
      const sortedResources: Resource[] = [
        {
          id: 'my-absences',
          sections: 'My Absences',
          title: loggedInUser?.name || '',
        },
      ];
      if (employees && employees.length > 0) {
        sortedResources.push(...employees
        .sort((a, b) => {
          const departmentA = a.department || 'Unknown';
          const departmentB = b.department || 'Unknown';
          return departmentA.localeCompare(departmentB) || a.name.localeCompare(b.name);
        })
        .map((employee) => ({
          id: employee.userId,
          sections: employee.department,
          title: employee.name || '',
        })));
      }
      setSortedResources(sortedResources);
    }
  },[employees, loggedInUser]);

  useEffect(() => {
    const events: EventSourceInput[] = [
      ...absences
        .filter(
          (absence) =>
            ['Requested', 'Approved'].includes(absence.status) &&
            absence.userId === loggedInUser?.userId
        )
        .map((absence) => {
          if (absence.id === undefined) {
            return {
              start: formatCalendarDate(absence.startDate),
              display: 'background',
              end: formatCalendarDate(absence.endDate),
              color: getAbsenceColor(absence.reason),
              borderColor: '#ddd',
              resourceId: 'my-absences',
              icon: getAbsenceIcon(absence),
            };
          }
          return {
            id: absence.id?.toString(),
            title: `${getFormattedPeriod(absence.startDate, absence.endDate)}\n${absence.reason}${absence.information === '' ? '' : ' - '}${absence.information}`,
            start: formatCalendarDate(absence.startDate),
            display: 'background',
            end: formatCalendarDate(absence.endDate),
            color: getAbsenceColor(absence.reason),
            borderColor: '#ddd',
            resourceId: 'my-absences',
            icon: getAbsenceIcon(absence),
          };
        }),
      ...bankHolidays.filter((holiday) => holiday.iso === loggedInUser?.c).map((holiday) => ({
        title: `${formatDate(new Date(holiday.date))}\n${holiday.name}`,
        start: holiday.date,
        display: 'background',
        color: '#67ADEE',
        borderColor: '#ddd',
        resourceId: 'my-absences',
        icon: <img src={BankHolidayIcon} alt={holiday.name} title={`${formatDate(new Date(holiday.date))}\n${holiday.name}`} />,
        countryCode: holiday.iso,
      })),
    ];

    if (employees && employees.length > 0) {
      events.push(
        ...absences
          .filter((absence) => ['Requested', 'Approved'].includes(absence.status) &&
            absence.userId !== loggedInUser?.userId)
          .map((absence) => {
            if (absence.id === undefined) {
              return {
                start: formatCalendarDate(absence.startDate),
                display: 'background',
                end: formatCalendarDate(absence.endDate),
                color: getAbsenceColor(absence.reason),
                borderColor: '#ddd',
                resourceId: absence.userId,
                icon: getAbsenceIcon(absence),
              } as EventSourceInput;
            }
            return {
              id: absence.id?.toString(),
              title: `${getFormattedPeriod(absence.startDate, absence.endDate)}\n${absence.reason}${absence.information === '' ? '' : ' - '}${absence.information}`,
              start: formatCalendarDate(absence.startDate),
              display: 'background',
              end: formatCalendarDate(absence.endDate),
              color: getAbsenceColor(absence.reason),
              borderColor: '#ddd',
              resourceId: absence.userId,
              icon: getAbsenceIcon(absence),
            } as EventSourceInput;
          }),

        ...employees.reduce((acc: EventSourceInput[], employee: User) => {
          acc.push(...bankHolidays.filter((holiday) => holiday.iso === employee.c).map((holiday) => ({
            title: `${formatDate(new Date(holiday.date))}\n${holiday.name}`,
            start: holiday.date,
            display: 'background',
            color: '#67ADEE',
            borderColor: '#ddd',
            resourceId: employee.userId,
            icon: <img src={BankHolidayIcon} alt={holiday.name} title={`${formatDate(new Date(holiday.date))}\n${holiday.name}`} />,
            countryCode: holiday.iso,
          })));
          return acc;
          
        },[] as EventSourceInput[]),
      );
    }
    setEvents(events);
  },[absences, employees, bankHolidays, loggedInUser]);

  useEffect(() => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const overlappingDates = getOverlappingDates(absences.filter((absence) => absence.status === 'Approved'), today, today);
    setTotalAway(overlappingDates.length);
  }, [absences]);

  useEffect(() => {
    if (allEmployees.length > 0 && directReports.length > 0) {
      switch(calendarFilter) {
        case 'all':
          setEmployees(allEmployees);
          break;
        case 'directReports':
          setEmployees(allEmployees
            .filter((employee) => directReports
            .map((directReport) => directReport.userId).includes(employee.userId)));
          break;
        case 'includeIndirectReports':
          setEmployees(allEmployees
            .filter((employee) => [...directReports, ...indirectReports]
            .map((report) => report.userId).includes(employee.userId)));
          break;
      }
    }
  },[allEmployees, directReports, indirectReports, calendarFilter, msal.instance]);

  return (
    <div className='cal-page'>
      <div className='cal-wrapper'>
        <div className='calendar-search-bar'>
          <input
            type='text'
            value={filterName}
            placeholder='Search employees...'
            className='calendar-search-bar-input'
            onChange={(e) => {
              const { value } = e.currentTarget;
              setFilterName(value);
            }}
          />
          {groups && groups.includes(process.env.REACT_APP_LEAD_GROUP as string) ?
          <div
            style={{
              marginBottom: 'auto',
              marginTop: '5px',
            }
          }>
            <select
              value={calendarFilter}
              onChange={(e) => {
                const { value } = e.currentTarget;
                setCalendarFilter(value as CalendarFilter);
              }}
            >
              <option value='all'>All employees</option>
              <option value='directReports'>Direct Reports</option>
              <option value='includeIndirectReports'>Include Indirect Reports</option>
            </select>
          </div> : <div
            style={{
              marginBottom: 'auto',
              marginTop: '5px',
            }
          }></div>}
        </div>
        <FullCalendar
          ref={fullCalendar}
          plugins={[resourceTimelinePlugin]}
          schedulerLicenseKey={process.env.REACT_APP_CALENDAR_LICENSE}
          initialView='resourceTimelineMonth'
          headerToolbar={{
            left: '',
            center: 'title',
            right: 'today prev,next',
          }}
          resourceAreaColumns={[
            {
              field: 'title',
              headerContent: 'Absences',
            },
          ]}
          resourceAreaWidth={calendarResourceAreaWidth > 768 ? '12%' : '33%'}
          resourceGroupField='sections'
          resources={loggedInUser === undefined ? undefined : sortedResources.filter((resource) => resource.title.toLowerCase().includes(filterName.toLowerCase()))}
          events={events}
          eventContent={(arg) => {
            const { icon } = arg.event.extendedProps;
            return <div className='calendar-event-wrapper' title={arg.event.title}>
                {icon}
              </div>;
          }}
          datesSet={(payload) => {
            const newCalendarDate = payload.start;
            newCalendarDate.setUTCFullYear(payload.start.getFullYear(), payload.start.getMonth(), payload.start.getDate());
            newCalendarDate.setUTCHours(0, 0, 0, 0);
            if (calendarDate.toISOString() !== newCalendarDate.toISOString()) {
              setCalendarDate(newCalendarDate);
            }
          }}
          contentHeight={'auto'}
          eventClick={async(payload) => {
            if (payload.event.id && groups !== undefined &&
              (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
              groups.includes(process.env.REACT_APP_HR_GROUP as string) ||
              groups.includes(process.env.REACT_APP_LEAD_GROUP as string))
            ) {
              const id = payload.event.id;
              const event = absences.find((absence) => absence.id?.toString() === id);
              if (event !== undefined && event.status === 'Approved') {
                if (!(groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
                  groups.includes(process.env.REACT_APP_HR_GROUP as string)) &&
                  groups.includes(process.env.REACT_APP_LEAD_GROUP as string) &&
                  (event.userId === loggedInUser?.userId ||
                    employees.find((user: User) => event.userId === user.userId && user.leadId === loggedInUser?.userId) === undefined)
                ) {
                  return;
                }
                if (window.confirm(`Are you sure you want to remove the Abscence?
                  ${event.reason}
                  ${getUsername(event.userId, [loggedInUser as User, ...employees])}
                  ${formatDate(new Date(event.startDate))} - ${formatDate(new Date(event.endDate))
                  }`)) {
                  await handleRequestHandled('remove', { id: parseInt(id, 10) }, msal.instance, () => {});
                  const login = await getLogin(msal.instance);
                  await fetchAbsences(login);
                }
              }
            }
          }}
          slotLaneDidMount={(args) => {
            if (args.date !== undefined) {
              const date = new Date();
              date.setUTCDate(args.date.getDate());
              date.setUTCHours(0, 0, 0, 0);
              args.el.title = formatDate(date);
            }
          }}
        />
      </div>
      <div className='calendar-footer-container'>
        {groups !== undefined &&
          (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
          groups.includes(process.env.REACT_APP_HR_GROUP as string) ||
          groups.includes(process.env.REACT_APP_FINANCE_GROUP as string)) ?
        <React.Fragment>
          <div className='calendar-export-wrapper'>
            <button
              className='request-button'
              onClick={() => exportAbsencesForMonth(absences, calendarDate, [...employees, loggedInUser as User], msal.instance)}
            >Export Month</button>
          </div>
          <div className='calendar-export-wrapper'>
            <button
              className='request-button'
              onClick={() => exportAbsencesForYear(absences, calendarYear, [...employees, loggedInUser as User], msal.instance)}
            >Export Year</button>
          </div></React.Fragment> : null}
        <div className='calendar-export-wrapper'>
          {`Employees away today: ${totalAway}`}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
