import { useState, useEffect, useCallback } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import AbsenceFormPopup from '../AbsenceForm/AbsenceFormPopUp';
import { Absence, Holiday, User } from '../../Types';
import './LeadView.css';
import openArrow from '../../assets/Icons/LeadIcons/openArrow.svg';
import { getLogin } from '../../helpers/authHelper';
import PendingRequests from '../Shared/PendingRequests';
import EmployeeList from '../Shared/EmployeeList';
import { getSickDaysForCurrentYearMappedToEmployeeId } from '../../helpers/sickDays';
import { getFutureAbsencesPlannedAmount, getHolidayBalance, getHolidayBalanceAsOfToday } from '../../helpers/holidays';

interface LeadViewProps {
  groups?: string[];
}

const LeadView = (props: LeadViewProps) => {
  const { groups } = props;
  const [absenceFormOpen, setAbsenceFormOpen] = useState(false);
  const [directReports, setDirectReports] = useState<User[]>([]);
  const [absences, setAbsences] = useState<Absence[]>([]);
  const [isDirectReportsOpen, setIsDirectReportsOpen] = useState(true);
  const [indirectReports, setIndirectReports] = useState<User[]>([]);
  const [isIndirectReportsOpen, setIsIndirectReportsOpen] = useState(false);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [filterName, setFilterName] = useState('');
  const [sickDaysMap, setSickDaysMap] = useState<{[key: string]: number}>({});
  const [holidayBalanceMap, setHolidayBalanceMap] = useState<{[key: string]: number}>({});
  const [vacationDaysAsOfTodayMap, setVacationDaysAsOfTodayMap] = useState<{[key: string]: number}>({});
  const [vacationDaysPlannedMap, setVacationDaysPlannedMap] = useState<{[key: string]: number}>({});
  const [sortByHolidayAccrual, setSortByHolidayAccrual] = useState(false);
  const [sortBySickDays, setSortBySickDays] = useState(false);
  const [sortByVacationDaysAsOfToday, setSortByVacationDaysAsOfToday] = useState(false);
  const [sortByVacationDaysPlanned, setSortByVacationDaysPlanned] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const msal = useMsal();

  const handleAbsenceFormOpen = () => {
    setAbsenceFormOpen(true);
  };

  const handleAbsenceFormClose = () => {
    setAbsenceFormOpen(false);
  };

  const toggleTeamMembers = () => {
    setIsDirectReportsOpen(!isDirectReportsOpen);
  };

  const toggleIndirectReports = () => {
    setIsIndirectReportsOpen(!isIndirectReportsOpen);
  };

  const fetchMyTeam = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASEURL}/my-team`,
        {
          headers: {
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      const directReportsData = response.data.filter((user: User) => user.leadId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);
      const indirectReportsData = response.data.filter((user: User) => user.leadId !== msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);

      const sortedDirectReports = directReportsData.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });

      if (indirectReportsData) {
        setIndirectReports(indirectReportsData.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        }));
      }

      setDirectReports(sortedDirectReports);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  },[msal.instance]);

  const fetchHolidayAccrual = useCallback(async() => {
    try {
      const login = await getLogin(msal.instance);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASEURL}/holidays`,
        {
          headers: {
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      setHolidays(response.data);
    } catch(error) {
      console.error('Error fetching holidays', error);
    }
  },[msal.instance]);

  const fetchTeamAbsences = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/absences`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      setAbsences(response.data);
    } catch (error) {
      console.error('Error fetching team absences:', error);
    }
  },[msal.instance]);

  useEffect(() => {
    fetchMyTeam();
    fetchTeamAbsences();
  }, [isAuthenticated, fetchMyTeam, fetchTeamAbsences]);

  useEffect(() => {
    const processAsync = async() => {
      const reports = [...directReports, ...indirectReports];
      if (absences.length > 0 && reports.length > 0) {
        const sickDaysMap = await getSickDaysForCurrentYearMappedToEmployeeId(reports, absences, msal.instance);
        setSickDaysMap(sickDaysMap);
      }

      if (holidays.length > 0 && reports.length > 0 && absences.length) {
        const newMap: {[key: string]: number} = {};
        const newVacationDaysAsOfTodayMap: {[key: string]: number} = {};
        const newVacationDaysPlannedMap: {[key: string]: number} =  {};
        reports.forEach(async(employee) => {
          const employeeInterface = {
            userId: employee.userId,
            name: employee.name,
            location: employee.c,
          };
          const employeeAbsences = absences.filter((absence) => absence.userId === employee.userId);
          newMap[employee.userId] = getHolidayBalance(employee.userId, holidays);
          const vacationDaysAsOfToday = await getHolidayBalanceAsOfToday(employeeInterface, employeeAbsences, holidays, msal.instance);
          newVacationDaysAsOfTodayMap[employee.userId] = vacationDaysAsOfToday;
          const vacationDaysPlanned = await getFutureAbsencesPlannedAmount(employeeInterface, employeeAbsences, msal.instance);
          newVacationDaysPlannedMap[employee.userId] = vacationDaysPlanned;
        });
        setHolidayBalanceMap(newMap);
        setVacationDaysAsOfTodayMap(newVacationDaysAsOfTodayMap);
        setVacationDaysPlannedMap(newVacationDaysPlannedMap);
      }
    };
    processAsync();
  }, [directReports, indirectReports, holidays, absences, msal.instance]);

  useEffect(() => {
    if (directReports.length > 0) {
      fetchHolidayAccrual();
    }
  },[directReports, fetchHolidayAccrual]);

  return (
    (groups && (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
    groups.includes(process.env.REACT_APP_HR_GROUP as string) ||
    groups.includes(process.env.REACT_APP_LEAD_GROUP as string))) ?
    <>
      <div className='wrapper'>
        <div className='bar'>
          <div className='search-bar'>
            <input
              type='text'
              placeholder='Search employees...'
              className='search-bar-input'
              value={filterName}
              onChange={(e) => {
                const { value } = e.currentTarget;
                setFilterName(value);
              }}
            />
          </div>

          <div className='title-button-container'>
            <h1 className='myteam-title'>My Team</h1>
            <button className='request-button' onClick={handleAbsenceFormOpen} style={{display: 'none'}}>
              Report Team Absence
            </button>
          </div>
        </div>

        <PendingRequests
          refreshView={() => {
            fetchHolidayAccrual();
          }}
          holidays={holidays}
          msalInstance={msal.instance}
          users={directReports}
          indirectReports={indirectReports}
          sickDaysMap={sickDaysMap}
          holidayBalanceMap={holidayBalanceMap}
          vacationDaysAsOfTodayMap={vacationDaysAsOfTodayMap}
          vacationDaysPlannedMap={vacationDaysPlannedMap}
          sortByHolidayAccrual={sortByHolidayAccrual}
          setSortByHolidayAccrual={setSortByHolidayAccrual}
          sortBySickDays={sortBySickDays}
          setSortBySickDays={setSortBySickDays}
          sortByVacationDaysAsOfToday={sortByVacationDaysAsOfToday}
          setSortByVacationDaysAsOfToday={setSortByVacationDaysAsOfToday}
          sortByVacationDaysPlanned={sortByVacationDaysPlanned}
          setSortByVacationDaysPlanned={setSortByVacationDaysPlanned}
        />

        <div className='columns-container'>
          <div className='column-team-title' style={{ width: '350px' }}>
            <div className='icon-title' onClick={toggleTeamMembers}>
              <img
                src={openArrow}
                alt='Open Arrow'
                style={{ width: '22px', height: '22px', transform: isDirectReportsOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }}
              />
              <h2>Direct Reports</h2>
            </div>
          </div>
        </div>
        {isDirectReportsOpen && <EmployeeList
          absences={absences}
          employees={directReports.filter((teamMember) => teamMember.name.toLowerCase().includes(filterName.toLowerCase()))}
          sickDaysMap={sickDaysMap}
          holidayBalanceMap={holidayBalanceMap}
          vacationDaysAsOfTodayMap={vacationDaysAsOfTodayMap}
          vacationDaysPlannedMap={vacationDaysPlannedMap}
          sortByHolidayAccrual={sortByHolidayAccrual}
          sortBySickDays={sortBySickDays}
          sortByVacationDaysAsOfToday={sortByVacationDaysAsOfToday}
          sortByVacationDaysPlanned={sortByVacationDaysPlanned}
        />}
        <div className='columns-container'>
          <div className='column-team-title' style={{ width: '350px' }}>
            <div className='icon-title' onClick={toggleIndirectReports}>
              <img
                src={openArrow}
                alt='Open Arrow'
                style={{
                  width: '22px',
                  height: '22px',
                  transform: isIndirectReportsOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                }}
              />
              <h2>Indirect Reports</h2>
            </div>
          </div>
        </div>

        {isIndirectReportsOpen && <EmployeeList
          employees={indirectReports.filter((teamMember) => teamMember.name.toLowerCase().includes(filterName.toLowerCase()))}
          absences={absences}
          sickDaysMap={sickDaysMap}
          holidayBalanceMap={holidayBalanceMap}
          vacationDaysAsOfTodayMap={vacationDaysAsOfTodayMap}
          vacationDaysPlannedMap={vacationDaysPlannedMap}
          sortByHolidayAccrual={sortByHolidayAccrual}
          sortBySickDays={sortBySickDays}
          sortByVacationDaysAsOfToday={sortByVacationDaysAsOfToday}
          sortByVacationDaysPlanned={sortByVacationDaysPlanned}
        />}
      </div>
      <AbsenceFormPopup
        open={absenceFormOpen}
        closePopUp={handleAbsenceFormClose as () => void}
      />
    </> : null
  );
};

export default LeadView;